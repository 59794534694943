import { Composer } from 'vue-i18n';

import { Project, Service, Exchange, Invoice, Company, Charge, User } from "@tolemac/grpc_web_api/tolemac/pub/model";
import { Subscription } from "@tolemac/grpc_web_api/tolemac/pub/subscription";
import { ErrorCode } from '@tolemac/grpc_web_api/tolemac/pub/common';

import { ContactStatus } from '@/store/store.account'

type ExtendedEnum<T> = T & {
    values: number[];
    translate: (v?: number | string, opt?: { prefix?: string; fallbckTranslate?: string }) => string;
}

export class EnumDisplay {

    UserRight: Omit<ExtendedEnum<typeof User.UserRight>, 'translate'>
    ProjectStatus: ExtendedEnum<typeof Project.ProjectStatus>
    ProjectDelivery: ExtendedEnum<typeof Project.ProjectDelivery>
    ProjectBusinessArea: ExtendedEnum<typeof Project.BusinessArea>

    ServiceUnit: ExtendedEnum<typeof Service.ServiceUnit>
    ServiceUnitBy: ExtendedEnum<typeof Service.ServiceUnit>
    ServiceUnitQuantity: ExtendedEnum<typeof Service.ServiceUnit>

    ServiceDisountType: Omit<ExtendedEnum<typeof Service.ServiceDisountType>, 'translate'>
    ExchangeType: ExtendedEnum<typeof Exchange.ExchangeType>
    InvoiceStatus: ExtendedEnum<typeof Invoice.InvoiceStatus>
    ChargeType: ExtendedEnum<typeof Charge.ChargeType>
    ChargeFrequency: ExtendedEnum<typeof Charge.ChargeFrequency>
    ChargeFrequencyUnit: ExtendedEnum<typeof Charge.ChargeFrequency>
    CompanyProspect: ExtendedEnum<typeof Company.CompanyProspection>
    CompanyType: typeof Company.CompanyType
    CompanyActivitySector: ExtendedEnum<typeof Company.ActivitySector>
    SubscriptionStatus: ExtendedEnum<typeof Subscription.SubscriptionStatus>

    ERROR_CODE: ExtendedEnum<typeof ErrorCode>

    ContactStatus: ExtendedEnum<typeof ContactStatus>


    constructor(private i18n: Composer) {
        this.UserRight = { ...User.UserRight, values: enumOrdinal(User.UserRight) }

        this.ProjectStatus = this.enumDisplay(Project.ProjectStatus, 'model.project.status')
        this.ProjectDelivery = this.enumDisplay(Project.ProjectDelivery, 'model.project.delivery_enum')
        this.ProjectBusinessArea = this.enumDisplay(Project.BusinessArea, 'model.project.businessArea_enum')

        this.ServiceUnit = this.enumDisplay(Service.ServiceUnit, 'model.service.unit')
        this.ServiceUnitBy = this.enumDisplay(Service.ServiceUnit, 'model.service.unit_by')
        this.ServiceUnitQuantity = this.enumDisplay(Service.ServiceUnit, 'model.service.unit_quantity')

        this.ServiceDisountType = { ...Service.ServiceDisountType, values: enumOrdinal(Service.ServiceDisountType) }

        this.ExchangeType = this.enumDisplay(Exchange.ExchangeType, 'model.exchange.types')

        this.InvoiceStatus = this.enumDisplay(Invoice.InvoiceStatus, 'model.invoice.status')

        this.ChargeType = this.enumDisplay(Charge.ChargeType, 'model.charge.type')
        this.ChargeFrequency = this.enumDisplay(Charge.ChargeFrequency, 'model.charge.frequency_enum')
        this.ChargeFrequencyUnit = this.enumDisplay(Charge.ChargeFrequency, 'model.charge.frequency_unit')

        this.CompanyType = Company.CompanyType
        this.CompanyProspect = this.enumDisplay(Company.CompanyProspection, 'model.company.prospect')
        this.CompanyActivitySector = this.enumDisplay(Company.ActivitySector, 'model.company.activity_sector')

        this.SubscriptionStatus = this.enumDisplay(Subscription.SubscriptionStatus, 'model.subscription.status')

        this.ERROR_CODE = this.enumDisplay(ErrorCode, 'errors', 'errors.TECHNICAL')

        this.ContactStatus = this.enumDisplay(ContactStatus, 'model.contact.status')
    }

    /**
     * <prefixTranslate>.<enum>
     * <prefixTranslate>.<fallback>
     * <prefixTranslate>.<globalFallback>
     * 
     * @param enumm an enum
     * @param prefixTranslate ex: 'model.xx.yyy' (see: src/locales/fr.json)
     * @param globalFallback fallback if <prefixTranslate>.<enum> haven't translation
     */
    enumDisplay<T>(enumm: T, prefixTranslate: string, globalFallback?: string): ExtendedEnum<T> {
        const ordinal = enumOrdinal(enumm)
        const i18n = this.i18n
        return {
            ...enumm,

            get values() { return ordinal },

            translate(o?: number | string, opt: { prefix?: string; fallbck?: string } = {}) {
                if (o === undefined) return ''

                const enumKey = typeof o === 'string' ? o : (enumm as any)[o]
                let result = i18n.t(`${prefixTranslate}.${enumKey}`, ' ')

                if (!result?.trim() && opt.fallbck)
                    result = i18n.t(`${prefixTranslate}.${opt.fallbck}`, ' ')

                if (!result?.trim() && globalFallback)
                    result = i18n.t(`${prefixTranslate}.${globalFallback}`, ' ')

                return result?.trim() ? (opt.prefix || '') + result : ''
            }

        }
    }
}

function enumOrdinal(enumm: any) {
    return Object.keys(enumm).filter(key => !isNaN(Number(key))).map(v => parseInt(v)).filter(x => !!x)
}