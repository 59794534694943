<template>
    <div class="grid grid-cols-1">

        <div class="w-full flex items-center gap-x-4 mb-4">
            <div class="w-12 h-12 leading-12 flex items-center justify-center bg-fluo-50 rounded-xl">
                <ReceiptPercentIcon class="text-fluo-700 w-7" />
            </div>
            <span class="flex-grow">
                <h2 class="text-lg font-medium">
                    {{ $t('page.settings_billing.section_tax.title') }}
                </h2>
                <p class="text-gray-500">
                    {{ $t('page.settings_billing.section_tax.description') }}
                </p>
            </span>
        </div>

        <div class="text-center">
            <!-- no data -->
            <template v-if="taxesRate.length === 0">
                <t-svg class="h-40" src="icon8/matey-online-preschool-education-at-home.svg" />
                <div class="font-bold">
                    {{ $t('page.settings_billing.section_tax.no_data_title') }}
                </div>
                <p class="text-gray-500">
                    {{ $t('page.settings_billing.section_tax.no_data_description') }}
                </p>
            </template>
            <!-- add new taxe -->
            <t-button :class="{ 'w-full': taxesRate.length }" color="dashed" @click="add()">
                {{ $t('page.settings_billing.section_tax.action_add_new') }}
            </t-button>
        </div>

        <div class="grid gap-y-2 mt-2">
            <div v-for="(t, tIdx) of taxesRate" :key="tIdx" class="bg-gray-50 rounded-md flex items-center gap-4 p-2">
                <t-input class="grow" v-model="t.name" :validation="validation[tIdx].name"
                    :placeholder="$t('page.settings_billing.section_tax.model_name')" />
                <t-input class="grow" v-model.float="t.value" :validation="validation[tIdx].value" end="%" />
                <input :checked="t.default" type="checkbox" @change="changeDefault(tIdx)" :disabled="t.default"
                    :title="t.default ? $t('page.settings_billing.section_tax.is_default') : $t('page.settings_billing.section_tax.use_default')"
                    :class="{ 'input-checkbox-invalid': validation[tIdx].default.$invalid }" />
                <t-button-icon @click="remove(tIdx, t.id)" :title="$t('actions.delete')">
                    <t-icon-trash />
                </t-button-icon>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { ReceiptPercentIcon } from '@heroicons/vue/24/outline'

import { ITaxRate } from '@tolemac/grpc_web_api/tolemac/pub/model'

import { type ValidationOf } from '@tolemac/web-components'

import plugins from '@/plugins'

export default defineComponent({
    props: {
        modelValue: Array as PropType<ITaxRate[]>,
        validation: { type: Object as PropType<ValidationOf<ITaxRate>[]>, required: true }
    },
    components: {
        ReceiptPercentIcon
    },
    emits: ['update:model-value', 'add', 'remove'],
    setup(props, { emit }) {

        const { $api, $t, modalCtl } = plugins

        const taxesRate = computed({
            get() { return props.modelValue || [] },
            set(l: ITaxRate[]) { emit('update:model-value', l) }
        })

        return {
            taxesRate,

            add() {
                taxesRate.value.unshift({ default: taxesRate.value.find(t => t.default) === undefined })
            },
            async remove(index: number, id?: number) {
                if (id) {
                    const _companies = await $api.company.find({
                        criterias: [{ field: 'taxRate.id', value: `${id}` }],
                        relations: ['none']
                    })
                    if (_companies.count! > 0) {
                        modalCtl.open({
                            title: $t('page.settings_billing.section_tax.modal_confirm_delete.title'),
                            message: $t('page.settings_billing.section_tax.modal_confirm_delete.message'),
                            buttons: [{ title: $t('actions.cancel') }]
                        })
                        return
                    }
                }
                const deletedTaxes = taxesRate.value.splice(index, 1)[0]
                if (deletedTaxes && deletedTaxes.default && taxesRate.value.length)
                    taxesRate.value[0].default = true
            },
            changeDefault(index: number) {
                const taxes = taxesRate.value
                const defaultT = taxes.find((t, tI) => t.default && tI !== index)
                if (defaultT) defaultT.default = false
                taxes[index].default = true
            }
        }
    }
})
</script>