<template>
    <div class="grid grid-cols-1">

        <div class="w-full flex items-center gap-x-4 mb-4">
            <div class="w-12 h-12 leading-12 flex items-center justify-center bg-fluo-50 rounded-xl">
                <LanguageIcon class="text-fluo-700 w-7" />
            </div>
            <span class="flex-grow">
                <h2 class="text-lg font-medium">
                    {{ $t('page.settings_activity.section_lang.title') }}
                </h2>
                <p class="text-gray-500">
                    {{ $t('page.settings_activity.section_lang.description') }}
                </p>
            </span>
        </div>

        <div class="text-center">
            <!-- no data -->
            <template v-if="orgaLangs.length === 0">
                <t-svg class="h-24" src="icon8/matey-online-preschool-education-at-home.svg" />
                <div class="font-bold">
                    {{ $t('page.settings_activity.section_lang.no_data.title') }}
                </div>
                <p class="text-gray-500">
                    {{ $t('page.settings_activity.section_lang.no_data.description') }}
                </p>
            </template>
            <!-- add new lang -->
            <LangCombobox :exclude="langs" @change="addLang"
                :placeholder="$t('page.settings_activity.section_lang.placeholder')" />
        </div>


        <div class="grid gap-y-2 mt-2 divide-y">
            <div v-for="(l, lIdx) of orgaLangs" :key="l.code"
                class="px-4 py-2 flex items-center w-full">
                <span class="text-xl">{{ l.flagCountry }}</span>
                <span class="pl-2">{{ l.name }}</span>

                <span class="flex-grow text-right ml-auto">
                    <t-button-icon @click="removeLang(lIdx)" title="supprimer">
                        <t-icon-trash />
                    </t-button-icon>
                </span>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { LanguageIcon } from '@heroicons/vue/24/outline'

import LangCombobox from './LangCombobox.vue'
import plugins from '@/plugins'

export default defineComponent({
    name: 't-lang-section',
    props: {
        modelValue: Array as PropType<string[]>
    },
    components: {
        LangCombobox,
        LanguageIcon
    },
    emits: ['update:model-value', 'add', 'remove'],
    setup(props, { emit }) {
        const { $languages, $api, $t, modalCtl } = plugins

        const langs = computed({
            get() { return props.modelValue || [] },
            set(l: string[]) { emit('update:model-value', l) }
        })

        return {
            langs,

            orgaLangs: computed(() => $languages.getAll(props.modelValue)),

            addLang(l?: LANG) {
                if (l) {
                    emit('add', l.code)
                    langs.value.push(l.code)
                }
            },

            async removeLang(lIdx: number) {
                const langToDelete = langs.value[lIdx];
                if (!langToDelete) return

                const langsUsed = await $api.service.find({
                    criterias: [
                        { field: 'langSrc', value: langToDelete, or: true },
                        { field: 'langTarget', value: langToDelete, or: true }
                    ],
                    relations: ['none']
                })

                if (langsUsed.count === 0) {
                    emit('remove', langToDelete)
                    langs.value.splice(lIdx, 1)
                } else {
                    modalCtl.open({
                        title: $t('page.settings_activity.section_lang.modal_confirm_delete.title'),
                        message: $t('page.settings_activity.section_lang.modal_confirm_delete.message'),
                        buttons: [{ title: $t('actions.cancel') }]
                    })
                }
            }
        }
    }
})
</script>