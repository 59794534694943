<template>
    <t-select v-model="lang" :placeholder="$props.placeholder" :validation="$props.validation" :options="languages"
        options-label="name" :options-value="l => l.code">
        <template v-slot="{ opt, selected }">
            <div class="flex flex-nowrap px-2 gap-x-4 w-full py-2">
                <span v-if="opt.codeCountry">{{ opt.flagCountry }}</span>
                <span :class="{ 'pr-9': !selected }">{{ opt.name }}</span>
                <span v-if="selected" class="flex-grow">
                    <t-icon-check class="ml-auto h-5 w-5" aria-hidden="true" />
                </span>
            </div>
        </template>
        <template v-slot:end="{ active }">
            <div class="w-full block py-2 px-3" :class="{ 'bg-ocean text-white': active, 'text-ocean': !active }"
                @click="addNewLanguage">
                <t-icon-plus class="h-4 w-4 inline-block" /> {{ $t('section.service.select_add_new_lang') }}
            </div>
        </template>
    </t-select>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from 'vue'
import { type ValidationOf } from '@tolemac/web-components'

import plugins from '@/plugins';

import LangModal from './LangModal.vue'

export default defineComponent({
    name: 't-lang-select',
    props: {
        modelValue: String,
        validation: Object as PropType<ValidationOf<string, { required: any }>>,
        placeholder: String,
        otherLang: String
    },
    mixins: [] as unknown as [GlobalComponentsMixin<'TSelect'>],
    setup(props, { emit }) {
        const { $store, $languages, modalCtl } = plugins

        return {
            lang: computed({
                get() {
                    return props.modelValue
                },
                set(l?: string) {
                    emit('update:modelValue', l)
                }
            }),
            languages: computed(() =>
                $languages
                    .getAll($store.account.$state.user?.orga?.langs)
                    .filter(l => l.code !== props.otherLang)
            ),
            async addNewLanguage() {
                const newL = await modalCtl.openCustom({
                    component: LangModal
                })
                emit('update:modelValue', typeof newL === 'string' ? newL : undefined)
            }
        }
    }
})
</script>