import { IService, IProjectService, IInvoiceItem, IProjectPriceGrid, IProjectStats, Service } from '@tolemac/grpc_web_api/tolemac/pub/model'

import { modalController } from '../modal'
import { objectClone } from '@/util';

import ServiceModal from '@/components/services/ServiceModal.vue'

type _ProjectStats = Required<Pick<IProjectStats, 'amount' | 'amountWords' | 'words' | 'weightedWords'>>

// IService = IProjectService with quantity undefined
export type IProjectServiceGrid = RequireOne<IProjectService, 'valuePriceGrid'>
export type _Service = IProjectService | IProjectServiceGrid | (IInvoiceItem & { valuePriceGrid: undefined })

export function isProjectPriceGrid(s: _Service): s is IProjectServiceGrid {
    return s.unit === Service.ServiceUnit.PRICE_GRID && "valuePriceGrid" in s
}

export const PRICE_GRID_ATTRS = ['vPerfectMatch', 'vContextMatch', 'vRepeats', 'vRepeatsBtwFiles', "v100", "v95v99", "v85v94", "v75v84", "v0v74", 'vRefAdaptativeMT', 'vAdaptativeMTWithLearning', 'vNewWords'] as const;
export type PriceGridValues = typeof PRICE_GRID_ATTRS[number];

/**
 * Service / ProjectServices / InvoiceItems
 */
export const service = {

    totalServices,
    totalService,

    quantityFloatPrecision,
    valueFloatPrecision,

    isProjectPriceGrid,
    PRICE_GRID_ATTRS,

    catalog: {
        openModalDuplicate(service: IService): Promise<IService | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { service, type: 'catalog', add: 'copy' }
            })
        },

        openModalEdit(service: IService): Promise<IService | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { service, type: 'catalog', add: false }
            })
        },

        openModalAdd(): Promise<IService | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { type: 'catalog', add: true }
            })
        }
    },

    project: {
        openModalAddService(): Promise<IProjectService | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { type: 'project', add: true }
            })
        },

        openModalEditService(service: IProjectService): Promise<IProjectService | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { service, type: 'project', add: false }
            })
        },
    },

    invoice: {
        openModalAddService(): Promise<IInvoiceItem | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { type: 'invoice', add: true }
            })
        },

        openModalEditService(service: IInvoiceItem): Promise<IInvoiceItem | undefined> {
            return modalController.openCustom({
                component: ServiceModal,
                componentProps: { service, type: 'invoice', add: false }
            })
        }
    }
}

export function quantityFloatPrecision(unit?: Service.ServiceUnit, isInvoice = false) {
    switch (unit) {
        case Service.ServiceUnit.WORD:
        case Service.ServiceUnit.PACKAGE:
            return 0
        case Service.ServiceUnit.PRICE_GRID:
            return isInvoice ? 0 : 2
        case Service.ServiceUnit.DAY:
            return 1
        case Service.ServiceUnit.HOUR:
        default:
            return 2
    }
}

export function valueFloatPrecision(unit?: Service.ServiceUnit, isInvoice = false) {
    switch (unit) {
        case Service.ServiceUnit.PRICE_GRID:
            return isInvoice ? 2 : 3
        default:
            return 2
    }
}

export function priceGridQuantity(priceGrid?: IProjectPriceGrid) {
    if (!priceGrid) return undefined
    return PRICE_GRID_ATTRS.reduce((tot, key) => {
        if (`q${key}` in priceGrid)
            return (tot || 0) + (priceGrid[`q${key}`] || 0)
        return tot
    }, undefined as undefined | number)
}

export function mapToInvoiceItem(_s: IService | IProjectService, keepQuantity = false): IInvoiceItem {
    const _ii = objectClone(_s, ['id', 'valuePriceGrid']) as IInvoiceItem

    if (_ii.unit === Service.ServiceUnit.PRICE_GRID) {
        if (_s.valuePriceGrid) {
            const _spp = _s.valuePriceGrid as IProjectPriceGrid
            _ii.value = PRICE_GRID_ATTRS
                .reduce((tot, key) => tot += (_spp[`${key}`] || 0) * (_spp[`q${key}`] || 0), 0)
            _ii.quantity = 1
        }
    }

    if (!keepQuantity) _ii.quantity = 0

    return _ii
}

export function mapToProjectService(_s: IService): IProjectService {
    const _ps = objectClone(_s, ['id']) as IProjectService
    if (_ps.unit === Service.ServiceUnit.PRICE_GRID) {
        if (!_ps.valuePriceGrid) _ps.valuePriceGrid = {}
        delete _ps.valuePriceGrid.id
        Object.assign(
            _ps.valuePriceGrid,
            PRICE_GRID_ATTRS.reduce((tot, key) => { tot[`q${key}`] = 0; return tot }, {} as IProjectPriceGrid)
        )
    } else {
        _ps.quantity = 1
    }
    return _ps
}

/**
 * @param services with valuePriceGrid
 */
export function totalServices(services: (IProjectService | IInvoiceItem)[] = []): _ProjectStats {
    return services.reduce((total, service) => {
        const t = totalService(service)
        return {
            words: total.words + t.words,
            amount: total.amount + t.amount + t.amountWords,
            amountWords: total.amountWords + t.amountWords,
            weightedWords: total.weightedWords + t.weightedWords
        }
    }, { words: 0, weightedWords: 0, amount: 0, amountWords: 0 })
}

/**
 * @param service with valuePriceGrid
 */
function totalService(service: IProjectService | IInvoiceItem): _ProjectStats {
    let addWords = 0, addAmount = 0, addAmountWords = 0, addWeightedWords = 0
    let isWord = false

    if (service.unit === Service.ServiceUnit.PRICE_GRID && 'valuePriceGrid' in service) {
        const tot = totalPriceGrid(service.valuePriceGrid!)
        addWords = tot.words
        addAmountWords = tot.sum
        addWeightedWords = tot.weightedWords
        isWord = true
    } else if (service.unit === Service.ServiceUnit.WORD) {
        addWords = service.quantity || 0
        addAmountWords = ((service.quantity || 0) * (service.value || 0))
        addWeightedWords = service.quantity || 0
        isWord = true
    } else {
        addAmount = ((service.quantity || 0) * (service.value || 0))
    }

    if (service.discountType === Service.ServiceDisountType.FIXED) {
        if (isWord)
            addAmountWords -= (service.discount || 0)
        else
            addAmount -= (service.discount || 0)
    } else if (service.discountType === Service.ServiceDisountType.VARIABLE) {
        if (isWord)
            addAmountWords -= addAmountWords * ((service.discount || 0) / 100)
        else
            addAmount -= addAmount * ((service.discount || 0) / 100)
    }

    return {
        words: addWords,
        weightedWords: addWeightedWords,
        amount: addAmount,
        amountWords: addAmountWords
    }
}

function totalPriceGrid(priceGrid: IProjectPriceGrid) {
    return PRICE_GRID_ATTRS.reduce(
        (total, key) => ({
            words: total.words + (priceGrid[`q${key}`] || 0),
            sum: total.sum + ((priceGrid[key] || 0) * (priceGrid[`q${key}`] || 0)),
            weightedWords: !priceGrid.basePrice ? total.weightedWords : total.weightedWords + (((priceGrid[key] || 0) / priceGrid.basePrice) * (priceGrid[`q${key}`] || 0))
        }),
        { words: 0, sum: 0, weightedWords: 0 }
    )
}